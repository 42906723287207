<template>
    <v-expansion-panel>
        <v-expansion-panel-header class="mt-2">
            BUSCAR PACIENTES DE VETERINARIA
            <template v-slot:actions>
                <v-btn class="mr-5" @click.native.stop="reloadPatientsV++" icon small>
                    <v-icon color="primary">mdi-reload</v-icon>
                </v-btn>
                <v-btn @click.prevent="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
                    <v-icon color="error">mdi-close</v-icon>
                </v-btn>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content :key="reloadPatientsV">
            <v-card>
                <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
                    <v-tabs-slider color="red"></v-tabs-slider>

                    <v-tab href="#tab-1">
                        GESTIÓN DE PACIENTES
                        <v-icon>mdi-account-search</v-icon>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-1">
                        <v-card flat>
                            <v-card-text class="px-1 py-1">
                                <GeneralFilter class="background border-box" :filterEndpoint="$API.veterinarypatients.filterPatients" :search="search" @emptyFilter="getAllPatients" @filtered="filterHandler">
                                    <v-row style="padding: 1rem;">
                                        <v-col cols="3" md="2" sm="3">
                                            <v-checkbox
                                                v-model="sourceFilter.patient"
                                                label="Por Paciente"
                                            >
                                            </v-checkbox>
                                        </v-col>
                                        <v-col cols="3" md="2" sm="3">
                                            <v-checkbox
                                                v-model="sourceFilter.customer"
                                                label="Por Cliente"
                                            >
                                            </v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <DxDataGrid class="isScrolledByGeneralFilter" :data-source="veterinarypatients" key-expr="ID_Patient"
                                                :show-row-lines="showRowLines"
                                                :show-borders="showBorders"  @exporting="onExporting"
                                                :row-alternation-enabled="rowAlternationEnabled">
                                        <DxPaging :page-size="10"/>
                                        <DxExport :enabled="true"/>
                                        <DxScrolling row-rendering-mode="virtual"/>
                                        <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
                                        <DxColumn data-field="ID_Patient" caption="ID de Paciente"></DxColumn>
                                        <DxColumn data-field="ID_Customer" caption="ID de Cliente"></DxColumn>
                                        <DxColumn data-field="FullName" caption="Nombre Cliente "></DxColumn>
                                        <DxColumn data-field="NamePatient" caption="Nombre Paciente"></DxColumn>
                                        <DxColumn data-field="Species" caption="Especie"></DxColumn>
                                        <DxColumn data-field="Breed" caption="Raza"></DxColumn>
                                        <DxColumn data-field="OldID" caption="Código alterno"></DxColumn> 
                                        <template #show-template="{data}">
                                            <a href="#" class="mx-1"
                                               @click="patientSelected(data)">
                                                <v-icon color="primary">mdi-eye</v-icon>
                                            </a>
                                        </template>
                                    </DxDataGrid>
                                </GeneralFilter>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import {DxDataGrid, DxColumn, DxScrolling, DxPaging,DxExport } from 'devextreme-vue/data-grid';
    import { mapGetters,mapState } from 'vuex'
    import { printToExcel } from "@/helpers/printToexcel";
    import GeneralFilter from '@/components/GeneralFilter.vue';

    export default {
        name: "VeterinaryPatientList",
        components: {DxDataGrid, DxColumn, DxScrolling, DxPaging, DxExport, GeneralFilter },
        props: ['win'],
        data() {
            return {
                sourceFilter: {
                    patient: true,
                    customer: false,
                },
                search: {},
                dialog: false,
                dialogDelete: false,
                tab: null,
                veterinarypatients: [],
                showPatient: false,
                showRowLines: true,
                showBorders: true,
                rowAlternationEnabled: true,
                reloadPatientsV: 0,
            }
        },
        computed: {
          ...mapGetters(['reloadData']),
          ...mapState(
      "rolesUser",
      {
        rolesStatus: (state) => state.roles,
      })
        },
        watch: {
            reloadData: {
                immediate: true,
                handler: 'reloadPatients'
            },
            'sourceFilter.patient': {
                immediate: true,
                deep: true,
                handler(val) {
                    if(val) this.search.patientFlag = true;
                    else this.search.patientFlag = false;
                }
            },
            'sourceFilter.customer': {
                immediate: true,
                deep: true,
                handler(val) {
                    if(val) this.search.customerFlag = true;
                    else this.search.customerFlag = false;
                }
            },
        },
        mounted() {
            this.search.business = JSON.parse(localStorage.getItem("user")).businessid || ""
            this.search.branch = JSON.parse(localStorage.getItem("user")).branch || ""
            this.getAllPatients();
        },
        methods: {
            filterHandler(response) {
                this.veterinarypatients = response
            },
            onExporting(e) {
                printToExcel(e, 'Pacientes');
            },
            getAllPatients() {
                this.$API.veterinarypatients.getPatientsHistory(this.search)
                    .then(response => {
                        this.veterinarypatients = response
                    })
            },
            async reloadPatients() {
                if (this.reloadData == 'veterinarypatients') {
                    await this.getAllPatients();
                    this.$store.dispatch('reloadData', null)
                }
                setTimeout(() => {
                window.scrollTo(0, this.$store.getters.getScrollTop)
                //alert(this.$store.getters.getScrollTop)
                },300);
            },
            patientSelected(data) {
                // this.$store.dispatch('deleteWindow', {name: 'patientData'});
                this.$API.customers.getOneCustomer(data.data.ID_Customer)
                .then((response) => {
                data.data.customer = response;
                
        
                this.$store.dispatch('addWindow', {
                    name: 'veterinaryPatientData',
                    component: 'VeterinaryPatientData',
                    unique: false,
                    meta: {veterinarypatients: data.data, reload:true }
                })
            })
            },
        },
    }
</script>

<style scoped>
    .v-input__control {
        background: white !important;
    }
    .border-box {
        border-radius: 15px !important;
    }
    .background {
        background: white !important;
        color: white;
    }
</style>
